
.dropzone-wrapper{
    border: 2px dashed #dadde8;
    padding: 1rem 2rem;
    text-align: center;
    transition: border-color .15s;
    color: #858585;
    cursor: pointer;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropzone-wrapper:focus{
    outline: none;
    border-color: #68ccc2;  
}

.upload-progress-bar-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}