.homepage-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 3rem;
}

.homepage-intro{
    margin: 5rem 0;
    text-align: center;
}

.homepage-intro h1{
    font-size: 5rem;
    font-weight: 100;
}

.homepage-intro h2{
    font-weight: 300;
}

.intro-cards{
    text-align: center;
    color: #323232;
}

.intro-cards .card-title{
    font-weight: 300;
    font-size: 2rem;
}

.intro-cards img{
    height: 10rem;
}
