
/*=================QUILL EDITOR=====================*/


#editor-show .ql-container.ql-snow{
    border: none;
}
#editor-show .ql-container {
font-size: 1rem;
}

#editor-show .ql-editor {
    padding: 0;
}
