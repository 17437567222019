.recorded-interview-header{
    background: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: -0.6rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.06);
}

.recorded-interview-header h3{
    font-size: 1.7rem;
    font-weight: 300;
}

.back-button{
    background: none !important;
    padding: 0 !important;
    min-width: unset;
}

.interview-question-text{
    margin: 0.7rem 0;
    font-size: 1.7rem;
    font-weight: 300;
}

.recorded-interview-iframe-wrapper{
    width: 100%;
    height: 34rem;
    border: 1px solid #dadde8;
    position: relative;
}

.recorded-interview-iframe{
    width: 100%;
    height: 100%;
    border: none;
}

.recorded-interview-iframe-overlay{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.5rem;
}

.answer-variant{
    padding: 0.5rem 1rem;
    margin: 0.3rem 0; 
    border-radius: .25rem;
    border: 1px solid #d6d8db;
}

.answer-variant-selected.answer-variant-incorrect{
    color: #d71c1c;
    background: #ffe9e9;
    border-color: #ffc7c7;
}

.answer-variant-selected.answer-variant-correct{
    color: #fff;
    background: #0db263;
    border-color: #0db263;
}

.answer-variant-correct:not(.answer-variant-selected){
    color: #00c768;
    border-color: #7ae8b4;
}

.answer-variant-selected:not(.answer-variant-correct):not(.answer-variant-incorrect){
    color: #fff;
    background: #0069d9;
    border-color: #0062cc;
}

.answer-variant-selected{ padding-right: 6.2rem; }
.answer-variant-selected:after{
    content: "Selected";
    position: absolute;
    right: 2.5rem;
}