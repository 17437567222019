.avatar-wrapper{ 
    position: relative; 
    display: inline-block;
}

.avatar-actions-wrapper{
    position: absolute;
    width: 10rem;
    height: 10rem;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #aeb9c1;
    transition: opacity .15s;
    opacity: 0;
}

.avatar-wrapper:hover .avatar-actions-wrapper{ opacity: 1; }