.candidate-card-header{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background .15s;
}
.candidate-card-header:hover{ background: #ebeef1 }

.interview-tab-content-wrapper{
    border: 1px solid #dee2e6;
    border-top: none;
    padding: 1rem;
    border-radius: 0 0 .25rem .25rem;
}

.cnd-pers-info-wrapper{
    display: grid;
    grid-template-columns: 1rem auto;
    align-items: center;
    grid-gap: .5rem;
}

.cnd-info-title{ 
    margin: 1.5rem 0;
    font-weight: 300; 
}
.cnd-info-title:first-of-type{ margin-top: 0; }

.candidate-status{ background-color: white; }
.candidate-status-icon{ font-size: 1.5rem; }

.card-candidate-applied{ border-left: 3px solid #7d7d7d !important }
.candidate-status-icon-applied{ color: #7d7d7d }

.card-candidate-rejected{ border-left: 3px solid #E46565 !important }
.candidate-status-icon-rejected{ color: #E46565 }

.card-candidate-scheduled{ border-left: 3px solid #029687 !important }
.candidate-status-icon-scheduled{ color: #029687 }

.card-candidate-refused{ border-left: 3px solid #E46565 !important }
.candidate-status-icon-refused{ color: #E46565 }

.card-candidate-pending{ border-left: 3px solid #79A7D5 !important }
.candidate-status-icon-pending{ color: #79A7D5 }

.card-candidate-accepted{ border-left: 3px solid #029687 !important }
.candidate-status-icon-accepted{ color: #029687 }

.card-candidate-recheck{ border-left: 3px solid #560296 !important }
.candidate-status-icon-recheck{ color: #560296 }
