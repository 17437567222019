.job-info-grid{
    display: grid;
    grid-template-columns: 1rem auto;
    align-items: center;
    grid-gap: .2rem;
}

.job-card-enabled { border-left: 3px solid #029687 !important; }
.job-card-disabled { border-left: 3px solid #e46565 !important; }

#job-title-div{
    margin-top: 2rem;
    margin-left: -10.5rem;
}

.selected-candidate {
    position: relative;
}

.btn-cnd-x {
    top: 0;
    right: 0;
    position: absolute;
    background-color: #515151;
    border: none;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #fff;
    font-size: .75rem;
}