.navbar{
    background: linear-gradient(90deg, #0f3254, #15655a);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.navbar-brand img { transition: opacity .15s; }
.navbar-brand img:hover { opacity: .8 }

.round-header-logo{
    height: 2.2rem;
    margin-left: 1rem;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #fff;
}

.navbar-brand{ padding: 0 !important; }

.navbar a { 
    word-break: normal; 
    transition: color .15s;
}

.navbar-padding{
    height: 4rem;
}