.footer{
    margin-bottom:0px;
    height:100%;
    width:100%;
    background: rgba(18, 60, 71, 0.7);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.my-container{
    width:1140px;
    color: rgb(255,255,255,0.7);
    font-family:"Roboto", sans-serif;
}

.footer-ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: end;
}

.footer-li{
    display:block;
    padding: 0.2rem;
    color: rgb(255,255,255,0.7) !important;
}

.footer-links{
    color: rgb(255,255,255,0.7) !important;
    textDecoration: 'none';
}
