.pending-interview-badge{
    min-width: 88px;
    height: 36px;
    padding: .375rem .75rem;
    border-radius: .25rem;
    color: #fff;
    background: #aeb9c1;
    text-align: center;
}

.status-toggle{
    margin-top: 0.4rem;
}

.all-assessments-list{
    padding: .375rem;
}

.assessment-item{
    padding: .375rem 1rem;
    cursor: pointer;
    transition: background .15s;
    border-radius: 1.15rem;
    margin: .1rem 0;
} 

.assessment-item:hover{
    background: #dadde8;
}

.assessment-item-selected{
    background: #15655a;
    padding-right: 2.5rem;
    color: #fff;
}

.assessment-item-selected:hover{ background: #1a8476; }

.assessment-item-selected:after{
    margin-left: .5rem;
    content: "×";
    position: absolute;
    right: 1.5rem;
}

.selected-assessment{
    padding: .375rem 1rem;
    border-radius: 1.15rem;
    cursor: pointer;
    background: #f2f3f8;
    border: 1px solid #dadde8;
    transition: background .15s;
}

.selected-assessment:after{
    margin-left: .5rem;
    content: "×";
}

.selected-assessment:hover{
    background: #dadde8;
}

.builder-tabs-wrapper{
    height: 3.5rem;
    border-bottom: 1px solid #dee0e2;
    display: flex;
    justify-content: flex-start;
    margin-left: 0px;
}

.builder-tab{
    flex: 0 0 20%;
    background: none;
    border: none;
    color: #454648;
    font-size: 1.1rem;
    transition: background .15s;
}

.builder-tab:hover{ background: #f3f5f7 }

.builder-tab:focus{ outline: none }

.builder-tab-active{
    border-top: 4px solid transparent;
    border-bottom: 4px solid #029687;
    color: #029687;
    font-weight: bold;
}