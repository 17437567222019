#rating-stars-upper-wrapper{
    overflow: hidden;
    position: absolute;
    top: 0;
    pointer-events: none;
}

.rating-star{ 
    height: 1.7rem; 
    pointer-events: none 
}

.rating-label{
    font-size: .85rem;
    color: #6c757d;
}